/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return ( 
      <div>
        {
        <div id="backgroundGrey" > 
          
          <Link to="/">
            <button id="homeButton">Alec Eames</button>
          </Link>
          <Link to="/projects/">
            <button class="menuButton" style={{left: '400px'}}>Projects</button>
          </Link>
          <Link to="/films/">
            <button class="menuButton" style={{left: "600px"}}>Films</button>
          </Link>
          <Link to="/essays/">
            <button class="menuButton" style={{left: "800px"}}>Essays</button>
          </Link>
          
          
          <div id="navBarGrey"></div>
          <div id="foregroundGrey">
          {children}
          </div>  
       
          <footer id="footer" style={{top: "97vh", position: 'absolute'}}> 
          </footer>
        </div>
        }

      <main id="children" opacity="1"></main> 

      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
