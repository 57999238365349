
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const Recommendations = () => (
  <Layout>
    <SEO title="Recommendations" />
    <h1></h1>
    <p></p>
  </Layout>
)

export default Recommendations
